/* jshint strict: false */
const $wrapper = document.querySelector('.wrapper'),
      $html    = document.querySelector('html'),
      $body    = document.querySelector('body')
;

$html.className = $html.className.replace(/\bno-js\b/, '') + ' js';

document.querySelectorAll('a[href^="#"]').forEach((linkElement) => {
  linkElement.addEventListener('click', (event) => {
    const href    = linkElement.href.split('#')
    const element = document.getElementById(href[1]);
    if (element) {
      element.scrollIntoView();
      event.preventDefault();
    }
  })
});

const fixFixedHeader = () => {
  const scrollTop = $wrapper.scrollTop,
        docHeight = document.documentElement.clientHeight || document.body.clientHeight;

  if (scrollTop < docHeight) {
    $body.className = $body.className.replace(/\b\s?fixed-header\b/, '');
  } else if ($body.className.search('fixed-header') === -1) {
    $body.className = $body.className + ' fixed-header';
  }
};

// adjust header image on scroll and resize event
if (document.querySelector('body.impressum') === null) {
  fixFixedHeader();

  $wrapper.addEventListener('touchmove', fixFixedHeader);
  $wrapper.addEventListener('scroll', fixFixedHeader);
  $wrapper.addEventListener('resize', fixFixedHeader);
}